<script setup lang="ts">
import locales from '~/assets/data/locales.json'

const route = useRoute()
const config = useRuntimeConfig()
const newUrl = useSetSubdomain(useGetSubdomain()) + route.path

const alternateLinks = locales.map((locale: any) => ({ rel: 'alternate', hreflang: locale.lang, href: useSetSubdomain(locale.region) }))
useHead({
  titleTemplate: (title: string) => computed(() => `${route.meta.title || title}`).value,
  meta: [
    { name: 'robots', content: 'nofollow, noindex' },
  ],
  link: [
    { rel: 'canonical', href: computed(() => newUrl) },
    { rel: 'alternate', hreflang: 'en', href: config.public.SITEMAP_HOST },
    ...alternateLinks,
  ],
})
</script>

<script lang="ts">
export default {
  layout: 'user',
}
</script>

<template>
  <div class="min-h-screen relative text-sm text-gray-700 flex flex-col bg-gray-50">
    <BaseOrganismsMainNavbar />

    <div class="w-full max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto pt-4 lg:pt-10 pb-20 lg:pb-40">
      <BaseOrganismsUserMenu />
      <DashboardVerifyPhone class="<lg:px-4" />
      <div class="<lg:px-4">
        <slot />
      </div>
    </div>
    <BaseOrganismsMainFooter class="mt-auto" />
  </div>
</template>
